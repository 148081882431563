import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App"
import { BrowserRouter,Route,  Routes } from 'react-router-dom';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalProviderPopupConfig } from './authentication/authConfig';
import { AppInsightsErrorBoundary, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

const pca = new PublicClientApplication(msalProviderPopupConfig.msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppInsightsContext.Provider value={reactPlugin}>
                <AppInsightsErrorBoundary appInsights={reactPlugin}>
                    <MsalProvider instance={pca}>
                        <Routes>
                            <Route path="/" element={<App />} />
                        </Routes>
                    </MsalProvider>
                </AppInsightsErrorBoundary>
            </AppInsightsContext.Provider>
                   
        </BrowserRouter>
    
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
