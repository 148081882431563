import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

export default function ActivateAccount({ marketplaceToken, offerData }) {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "ActivateAccount" });

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const timer = React.useRef();
    const hapUrl = process.env.REACT_APP_HAP_BASE_URL ?? "missing-hap-url";
    const HAP_API_BASEURL = process.env.REACT_APP_HAP_API_BASE_URL ?? 'no_base_hap_url';

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const redirectToHAP = () => {
        appInsights.trackEvent({ name: "RedirectingHARP" });


        window.location.replace(hapUrl + "login/sso?layout=embedded");
    };

    const activateAccountHap = (marketplaceToken, subscriptionId, planId, quantity) => {
        return axios.post(`${HAP_API_BASEURL}Auth/offer/activate?marketplaceToken=${marketplaceToken}`,
            {
                subscriptionId,
                planId,
                quantity : quantity === "0" ? "1" : quantity
            })
            .then((response) => {
                appInsights.trackEvent({ name: "AccountActivated" });
                return response.data;
            });

    }

    const handleButtonClick = () => {
        if (success) {
            return;
        }
        if (!loading) {
            setSuccess(false);
            setLoading(true);
            if (offerData && offerData.subscription && offerData.subscription.saasSubscriptionStatus === "Subscribed") {
                setSuccess(true);
                setLoading(false);
                appInsights.trackEvent({ name: "RedirectRegisteredUser" });
                redirectToHAP();
                return;
            }

            activateAccountHap(marketplaceToken, offerData.subscription.id, offerData.planId, offerData.quantity)
                .then(() => {
                    setSuccess(true);
                    setLoading(false);
                    timer.current = window.setTimeout(() => {
                        redirectToHAP();
                    }, 1500);
                })
                .catch((e) => {
                    console.log(e);
                    if (e && e.response && e.response.data && e.response.data.Message === 'Tenant already exists') {
                        setSuccess(true);
                        setLoading(false);
                        timer.current = window.setTimeout(() => {
                            redirectToHAP();
                        }, 1500);
                    }
                    else
                        setLoading(false);
                });
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>           
            <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                    variant="contained"
                    sx={buttonSx}
                    disabled={loading}
                    onClick={handleButtonClick}
                >
                    {success ? ("Account Activated !") : ("Activate Account")}
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Box>
    );
}