import './App.css';
import * as React from 'react';
import { Navigate, Route, Routes, useSearchParams  } from 'react-router-dom';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { BrowserAuthError, InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material';
import { Client } from "@microsoft/microsoft-graph-client";

import ActivateAccount from './ActivateAccount';
import { msalProviderPopupConfig } from './authentication/authConfig';

import axios from 'axios';

const HAP_API_BASEURL = process.env.REACT_APP_HAP_API_BASE_URL ?? 'no_base_hap_url';

const StyledTypography = styled(Typography)({
    wordBreak: "break-word",
    textAlign: "center",
    color: "white",
    "fontWeight": "800",
    "fontFamily": "Paralucent,Mulish,Muli,sans-serif"
});

const StyledTypographyGridLabel = styled(Typography)({
    fontSize: "12px",
    fontSize: "1rem",
    fontWeight: "bold",
    "fontFamily": "Paralucent,Mulish,Muli,sans-serif"
});

const StyledTypographyGridItem = styled(Typography)({
    fontSize: "12px",
    fontSize: "1rem",
    "fontFamily": "Paralucent,Mulish,Muli,sans-serif"
});

const StyledGridItemResult = styled(Grid)({
    textAlign: "center"
});

const tokenErrorMessage = "We couldn't identify this purchase. Please reopen HARP SaaS subscription in the Azure portal or in Microsoft 365 Admin Center and select 'Configure Account' or 'Manage Account' again.";

function App() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Onboarding" });

    const { instance, inProgress, accounts } = useMsal();
    const [offerData, setOfferData] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [marketplaceToken, setMarketplaceToken] = React.useState(null);

    const [searchParams, setSearchParams] = useSearchParams();

    React.useEffect(() => {
        //searchParams encodes the uri by default and the service requires decoded
        const marketplaceToken = searchParams.get("token");
        if (!marketplaceToken) {
            appInsights.trackEvent({ name: "NoMarketplaceToken" });
            setError("No Marketplace token provided");
        } else {
            setMarketplaceToken(encodeURIComponent(marketplaceToken));
        }
    }, [searchParams.get("token")]);

    React.useEffect(() => {
        if (!offerData && marketplaceToken && inProgress === InteractionStatus.None) {
            
            const accessTokenRequest = {
                scopes: msalProviderPopupConfig.silentRequestConfig.scopes,
                account: accounts[0]
            }
            
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                const authProvider = function (callback) {
                    callback(error, accessToken);
                };

                let options = {
                    authProvider
                };

                const client = Client.init(options);
                
                GetSubscriptionInformation(marketplaceToken, accessToken)
                    .then((response) => {
                        //TODO: tenant under accounts[0].tenant could be different from the one that ordered and shows on beneficiary
                        //so we just fail silently
                        const tenantId = response.subscription.beneficiary.tenantId;
                        return client.api("/organization/" + tenantId).get().then((organization) => {
                            if (organization && organization.displayName) {
                                appInsights.trackEvent({ name: "SuccessGettingOrgName" });

                                    response.subscription.beneficiary.displayName = organization.displayName;
                                } else {
                                    response.subscription.beneficiary.displayName = "";
                                }
                            setOfferData(response);
                            })
                            .catch((e) => {
                                appInsights.trackEvent({ name: "NoAccessOrgAPI" });
                                response.subscription.beneficiary.displayName = "";
                                setOfferData(response)
                            });
                    });
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError ||
                    error instanceof BrowserAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest)
                }
            })
        }
    }, [instance, accounts, inProgress, offerData, marketplaceToken]);

    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <AuthenticatedApp error={error} offerData={offerData} marketplaceToken={marketplaceToken} />
            </ AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnauthenticatedApp error={error} progress={inProgress} />
            </UnauthenticatedTemplate>
        </React.Fragment>
    )
}

function AuthenticatedApp({ offerData,marketplaceToken, error }) {

    const [isLoading, setIsloading] = React.useState(true);

    React.useEffect(() => {
        if (offerData || error) {
            setIsloading(false);
        }
    }, [offerData, error]);

    return (<React.Fragment>
            < CssBaseline />
            <Box display="flex"
            justifyContent="center"                
            sx={{ bgcolor: 'rgba(0,63,166)', height: '100vh', maxWidth: '100vw' }}>
            <Stack alignItems="center" direction={'column'} spacing={12}>
                    <Container maxWidth="false">
                        <StyledTypography variant="h2">HARP Onboarding</StyledTypography>
                    <StyledTypography style={{ color: "darkgrey" }} variant="h4">Let's get everything ready for you! :)</StyledTypography>
                </Container>
                    {isLoading ?
                    <Fade in={isLoading}>
                        <CircularProgress size="10rem" />
                    </Fade> :
                    <Fade in={!isLoading}>
                        {error ?
                            <StyledTypography style={{ marginTop: "20%", color: "red" }} variant="h5">{error}</StyledTypography> :
                            <Container style={{ borderRadius: '16px', padding: "5px", backgroundColor: "white", width: "38vw", height: "34vh" }}>
                                <StyledTypography variant="h1" fontSize={"1.5em"} style={{ color: "black" }}>{offerData.subscription.beneficiary.displayName}</StyledTypography>                                
                                <Grid style={{ marginTop: 0 }} container spacing={2}>
                                    <Grid item zeroMinWidth xs={12} sm={6}>
                                        <StyledTypographyGridLabel>
                                            Subscription Name:
                                        </StyledTypographyGridLabel>
                                    </Grid>
                                    <StyledGridItemResult zeroMinWidth item xs={12} sm={6}>
                                        <StyledTypographyGridItem>
                                            {offerData.subscriptionName}
                                        </StyledTypographyGridItem>
                                    </StyledGridItemResult>
                                    <Grid item zeroMinWidth xs={12} sm={6}>
                                        <StyledTypographyGridLabel>
                                            Plan:
                                        </StyledTypographyGridLabel>
                                    </Grid>
                                    <StyledGridItemResult zeroMinWidth item xs={12} sm={6}>
                                        <StyledTypographyGridItem>
                                            {offerData.planId}
                                        </StyledTypographyGridItem>
                                    </StyledGridItemResult>
                                    <Grid item zeroMinWidth xs={12} sm={6}>
                                        <StyledTypographyGridLabel>
                                            User seats:
                                        </StyledTypographyGridLabel>
                                    </Grid>
                                    <StyledGridItemResult zeroMinWidth item xs={12} sm={6}>
                                        <StyledTypographyGridItem>
                                            {offerData.quantity}
                                        </StyledTypographyGridItem>
                                    </StyledGridItemResult>
                                    <Grid item zeroMinWidth xs={12} sm={6}>
                                        <StyledTypographyGridLabel>
                                            Admin Email:
                                        </StyledTypographyGridLabel>
                                    </Grid>
                                    <StyledGridItemResult zeroMinWidth item xs={12} sm={6}>
                                        <StyledTypographyGridItem>
                                            {offerData.subscription.beneficiary.emailId}
                                        </StyledTypographyGridItem>
                                    </StyledGridItemResult>
                                </Grid>
                                <ActivateAccount marketplaceToken={marketplaceToken} offerData={offerData} />
                            </Container>}
                    </Fade>
                }
                { /* TODO: Add the href to the sales */}
                <StyledTypography style={{ color: "white" }} variant="span">Using HARP with Dynamics 365 Business Central? Please <a style={{ color: "white" }} href="https://bluefort-academy.thinkific.com/courses/take/lisa-business-subscription-automation-for-microsoft-dynamics-365-business-central">Click Here</a> for the documentation.</StyledTypography>
                </Stack>
            </Box>

            </React.Fragment>)
}

function UnauthenticatedApp({error}) {
    const [isLoading, setIsloading] = React.useState(true);

    React.useEffect(() => {
        if (error) {
            setIsloading(false);
        }
    }, [ error]);

    return (<React.Fragment>
        < CssBaseline />        
        <Box display="flex"
            justifyContent="center"
            sx={{ bgcolor: 'rgba(0,63,166)', height: '100vh', maxWidth: '100vw' }}>
            <Stack alignItems="center" direction={'column'} spacing={12}>
                <Container maxWidth="false">
                    <StyledTypography variant="h2">HARP Onboarding</StyledTypography>
                    <StyledTypography style={{ color: "darkgrey" }} variant="h4">Let's get everything ready for you :)</StyledTypography>
                </Container>
                {isLoading ?
                    <Fade in={isLoading}>
                        <CircularProgress size="10rem" />
                    </Fade> :
                    <Fade in={!isLoading}>
                        {error ?
                            <StyledTypography style={{ marginTop: "20%", color: "red" }} variant="h5">{error}</StyledTypography> :
                            error}
                    </Fade>}
                { /* TODO: Add the href to the sales */ }
                <StyledTypography style={{ color: "white" }} variant="span">Using HARP with Dynamics 365 Business Central? Please <a style={{ color: "white" }} href="https://bluefort-academy.thinkific.com/courses/take/lisa-business-subscription-automation-for-microsoft-dynamics-365-business-central">Click Here</a> for the documentation.</StyledTypography>
            </Stack>
        </Box>
        
    </React.Fragment>)
}
function GetSubscriptionInformation(marketplaceToken, accessToken) {
    return axios.get(`${HAP_API_BASEURL}Auth/offer/resolve?marketplaceToken=${marketplaceToken}`)
        .then((response) => {
            return response.data;
        });    
}

const sampleSubscriptionData = {
    "id": "5234e4cc-5dca-4b7a-a099-b4680f043a04", // purchased SaaS subscription ID
    "subscriptionName": "Bluefort HAP Subscription", // SaaS subscription name
    "offerId": "offer1", // purchased offer ID
    "planId": "silver", // purchased offer's plan ID
    "quantity": "20", // number of purchased seats, might be empty if the plan is not per seat
    "subscription": { // full SaaS subscription details, see Get Subscription APIs response body for full description
        "id": "5234e4cc-5dca-4b7a-a099-b4680f043a04",
        "saasSubscriptionStatus": " PendingFulfillmentStart ",
        "beneficiary": {
            "emailId": "test@test.com",
        "objectId": "9b903921-83a2-49d6-b996-38a63211e886",
            "tenantId": "9cc71614-f1d3-4559-81e3-3b03233c207e",
            "pid": "4fd00b59-406c-4172-93ec-b1f6626b3a47"
        }      
    }
}
export default App;
